import React from "react";
export const HeaderModule = () => {
    return (
        <>
        <span>
            <li className="module-title module-item main-title">Modules</li>
            <div className="module-block">
                <div className="module-block-column">
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritbackoffice">TravelSpirit BackOffice</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#crm">CRM</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#telephone">Telefonie</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#emails">Emails</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#workflow-management">Workflow Management</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#travelplans-product">Travelplans &amp;Producten</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#bestellingen">Bestellingen</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#foto-beheer-mediaspirit">Fotobeheer(MediaSpirit)</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#make-invoice">Facturen maken</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#business-intelligence">Business Intelligence</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice#documenten">Documenten</a></li>
                        <li className="module-item"><a href="/travelspiritbackoffice">Bekijk meer&gt;</a></li>
                    </span>
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritvisualtourbuilder">Visual Tour Builder</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder#drag-drop">Drag &amp; Drop</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder#customized-online-quotes">Online offertes op maat</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder#calculate">Calculeren</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder#voorbeeldreizen">Voorbeeldreizen</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder#pax-management">Pax Management</a></li>
                        <li className="module-item"><a href="/travelspiritvisualtourbuilder">Bekijk meer&gt;</a></li>
                    </span>
                </div>
                <div className="module-block-column">
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritfinance">Finance &amp; Accounting</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#accounting-links">Boekhoudkoppelingen</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#accounts-credit">Crediteurenbeheer</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#accounts-debit">Debiteurenbeheer</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#payment-links">Payment links</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#travel-agency">Reisbureauregeling</a></li>
                        <li className="module-item"><a href="/travelspiritfinance#einvoice">eFacturen</a></li>
                        <li className="module-item"><a href="/travelspiritfinance">Bekijk meer&gt;</a></li>
                    </span>
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritvluchten">Vluchten</a></li>
                        <li className="module-item"><a href="/travelspiritvluchten#import-flight">Importeren vluchten (GDS,
                            Airtrade)</a></li>
                        <li className="module-item"><a href="/travelspiritvluchten#read-settlement">Afrekening inlezen (IATA HOT,
                            Airtrade)</a>
                        </li>
                        <li className="module-item"><a href="/travelspiritvluchten">Bekijk meer&gt;</a></li>
                    </span>
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritwebsite">Website(SPoE)</a></li>
                        <li className="module-item"><a href="/travelspiritwebsite#single-point-of-entry">Single Point of Entry</a></li>
                        <li className="module-item"><a href="/travelspiritwebsite#products-package-content">Producten &amp; pakketten
                            content</a></li>
                        <li className="module-item"><a href="/travelspiritwebsite#photo-management-mediaspirit">Fotobeheer
                            (MediaSpirit)</a>
                        </li>
                        <li className="module-item"><a href="/travelspiritwebsite#online-booking">Online Boeken</a></li>
                        <li className="module-item"><a href="/travelspiritwebsite">Bekijk meer&gt;</a></li>
                    </span>
                </div>
                <div className="module-block-column">
                    <span>
                        <li className="module-title module-item"><a href="/travelspiritmarketing">Marketing</a></li>
                        <li className="module-item"><a href="/travelspiritmarketing#e-mailmarketing">E-mailmarketing</a></li>
                        <li className="module-item"><a href="/travelspiritmarketing#customer-reviews">Klantreviews</a></li>
                        <li className="module-item"><a href="/travelspiritmarketing#marketing-segmentation">Klantsegmentatie</a></li>
                        <li className="module-item"><a href="/travelspiritmarketing">Bekijk meer&gt;</a></li>
                    </span>
                </div>
            </div>
        </span><span>
                <li className="module-title module-item main-title">Integraties</li>
                <div className="module-block integrations-title">
                    <div className="module-block-column">
                        <span>
                            <li className="module-title module-item"><a href="/">Partnerkoppelingen</a></li>
                            <li className="module-item"><a href="/">Mobiele App (Appit)</a></li>
                            <li className="module-item"><a href="/">Internet op reis (Hubby eSIM)</a></li>
                            <li className="module-item"><a href="/">Payment links (Mollie)</a></li>
                            <li className="module-item"><a href="/">Boekhouding (Exact)</a></li>
                            <li className="module-item"><a href="/">E-mailmarketing (Spotler)</a></li>
                            <li className="module-item"><a href="/">Bekijk meer&gt;</a></li>
                        </span>
                    </div>
                </div>
            </span></>
    );
};

// You can add more modules here as needed