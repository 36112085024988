/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import site from "../components/data/seo.json";

function SEO({ description, lang, meta, title, urlParams, page = '', crawlable = "true" }) {
  const robots = [];

  if (crawlable === "false") {
    robots.push(
      {
        name: 'robots',
        content: 'noindex'
      }
    );
  } else {
    robots.push(
      {
        name: `robots`,
        content: `index, follow`,
      }
    );
  }

  const metaDescription = description || site.siteMetadata.description;
  let titleTemplate = '';

  if (urlParams === 'compleet') {
    titleTemplate = `TravelSpirit Compleet | %s`;
  } else {
    if (page !== '') {
      titleTemplate = `${site.siteMetadata.title} | %s`;
    } else {
      titleTemplate = `%s | ${site.siteMetadata.title}`;
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `cgqiwwq1-Bt0WwnksqKkJJSwgqdinhPYHWHyKx9z-no`,
        },
        {
          name: `msvalidate.01`,
          content: `8857A15B77D8557392D8D018D06EFAD0`,
        },
        {
          name: `google-site-verification`,
          content: `IucO7KR1-yzHcvXa2YF7lofAuyK3sukTMLjokn3K9WY`,
        },
        {
          name: `msvalidate.01`,
          content: `8857A15B77D8557392D8D018D06EFAD0`,
        }
      ].concat(meta).concat(robots)}
    >
      <link rel="preconnect" href="fonts.gstatic.com" crossorigin></link>
      <link rel="preload" href="https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap" as="style" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap" />
      <link rel="canonical" href={"https://travelspirit.nl/" + urlParams} />
      <link rel='stylesheet' href='https://static.mailplus.nl/jq/ui/jquery-ui-1.13.2.custom.min.css' type='text/css'></link>
      <script type='text/javascript' src='https://static.mailplus.nl/jq/jquery-3.6.0.min.js' language='javascript'></script>
      <script type='text/javascript' src='https://static.mailplus.nl/jq/jquery.validate.1.19.3.min.js' language='javascript'></script>
      <script type='text/javascript' src='https://static.mailplus.nl/jq/ui/jquery-ui-1.13.2.custom.min.js' language='javascript'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=RpPvABh6UMD4wG9y9VHG&p=https&om=d&of=x&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=RpPvABh6UMD4wG9y9VHG&p=https&om=d&of=h&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=wHWwTrW3ENzmjHW67Lr6&p=https&om=d&of=x&c=1&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=nqRhQ3m94658TpgNs8yC&p=https&om=d&of=x&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=qh592MqkYxFgWarDci26&p=https&om=d&of=x&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=WQXIvJtmYNA4NMe6xGIL&p=https&om=d&of=h&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=wHWwTrW3ENzmjHW67Lr6&p=https&om=d&of=h&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=WKITc9UJyFBLt2pydnKM&p=https&om=d&of=h&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=BJFj3UQf3IumgHvdfTHk&p=https&om=d&of=h&c=1&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=5rDXu5h3J5whZTCrnhZy&p=https&om=d&of=h&c=1&jq=3'></script>
      <script type='text/javascript' src='https://m13.mailplus.nl/genericservice/code/servlet/React?command=getFormJs&uid=2110488&feid=BJFj3UQf3IumgHvdfTHk&p=https&om=d&of=x&jq=3'></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
